import { useApolloClient } from "@apollo/client";
import AddApplicationPopup from "@src/common/AddApplicationPopup";
import { createClaimFields, validateInputFields } from "@src/common/FunctionList";
import { addCurrencyFormatting } from "@src/config/helper";
import { setClaimInsuranceData } from "@src/store/slices/claim";
import { getMasterConfig } from "@store/slices/common";
import { cloneDeep, times } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Modal from "../../../common/ModalPopup";
import { createClaim, getInsuranceForClaimById, getMMVList } from "../../../store/action/allAction";
import FullPageLoader from "../../../view/common/FullPageLoader";
import DynamicFormFields from "./DynamicFormFields";


export default function AddClaimApplication({ showAddApplicationPopup, hideAddApplicationPopup, getClaimList }: any) {
    const [loading, setLoading] = useState(false)
    const [formFields, setFormFields] = useState<any>([])
    const [masterData, setMasterData] = useState<any>({})
    const [refresh, setRefresh] = useState<boolean>(false)
    const [popupFields, setPopupFields] = useState({})
    const [showApplicationPopup, setShowApplicationPopup] = useState(false)
    const [claimType, setClaimType] = useState<any>([]);

    const client = useApolloClient();
    const dispatch: any = useDispatch()

    const commonState = useSelector((state: any) => state?.common)
    const claimState = useSelector((state: any) => state.claim)

    // const fetchInsuranceForClaim = async () => {
    //     setLoading(true)
    //     await dispatch(getInsuranceForClaim(client, { page_no: 1, query: '' }))
    // }

    const fetchInsuranceForClaimById = async (params: any) => {
        setLoading(true)
        const data: any = await dispatch(getInsuranceForClaimById(client, params))
        if(data?.status ===200){
            setClaimType(data?.data?.raise_claim);

        }
        //case when searchInsurance API is called
        if (params.plate_number && data?.message) {
            if (data.message !== 'Success' && data?.data?.lead_id !== params?.id) {
                toast.error(data.message)
            }
        }
        setLoading(false)
    }

    const fetchMMVList: any = async (params: any) => {
        setLoading(true);
        let mmvList: any = null;
        const category = params.category;
        if (!commonState?.mmvList[category]) {
            mmvList = await dispatch(getMMVList(client, { params: { ...params } }));
            mmvList = mmvList?.data;
        } else {
            mmvList = commonState?.mmvList[category];
        }
        setLoading(false);
        return mmvList;
    }

    const fetchMasterData = async () => {
        let data: any = null
        if (commonState?.common && Object.keys(commonState.common?.length || {})) {
            data = commonState.common
        } else {
            data = await dispatch(getMasterConfig())
        }

        const regionList: any = commonState?.regionList;
        setMasterData({...data, region_list: regionList.data });
    }


    const fetchFormConfig = async () => {        
        let result = cloneDeep(commonState?.formFields);
        setFormFields(result);
        updateFormFields(result)
    }

    const updateFormFields = (fields: any) => {

        let mappedFields: any = {
            vehicle_type_id: 'vehicle_type',
            partner_id: 'partner_list',
            region: 'region_list'
        }

        for (let field of fields) {
            let fieldName: any = mappedFields?.[field.name] || field.name

            if (fieldName === 'insurance_id' && claimState?.insuranceLeads?.length) {
                field['options'] = claimState?.insuranceLeads
            }

            else if (masterData?.[fieldName]) {
                field['options'] = masterData[fieldName]
            }
        }

        setFormFields([...fields])
        setRefresh(!refresh)
    }

    const validateInputField = () => {
        let error = false
        error = validateInputFields(formFields)

        setRefresh(!refresh)
        return error
    }

    const handleSubmit = async () => {
        setLoading(true)

        const errorExist = validateInputField()
        if (errorExist) {
            setLoading(false)
            return
        }

        let { claimDetails, customerDetails, vehicleDetails } = createClaimFields(formFields, '', claimState.insuranceData)
        let createFields: any = {
            claim_details: claimDetails,
            vehicle_details: vehicleDetails,
            customer_details: customerDetails,
            documents: claimState?.insuranceData?.documents || []
        }

        const result: any = await createClaim(client, createFields)
        if (result?.data?.create_claim?.data?.id) {
            let { data } = result?.data?.create_claim
            setPopupFields({ ...data })
            setShowApplicationPopup(true)
            dispatch(setClaimInsuranceData({}))
            getClaimList({ page_number: 1, searchKeyword: '' }, 'reset')
        } else {
            toast.error('Error in Creating Application')
        }
        setLoading(false)
    }

    useEffect(() => {
        try {
            setLoading(true)
            dispatch(setClaimInsuranceData({}))
            fetchMasterData()
        } finally {
            setLoading(false)
        }

        return (() => {
            dispatch(setClaimInsuranceData({}))
        })
    }, [])

    useEffect(() => {
        if (masterData && Object.keys(masterData)?.length) {            
            fetchFormConfig()
        }
    }, [masterData])

    useEffect(() => {
        if (claimState?.insuranceLeads?.length) {
            updateFormFields(formFields)
        }
    }, [claimState?.insuranceLeads])
    return (
        <>
            <FullPageLoader show={loading} />
            <div className="model-outer add-application-popup add-claim-popup">
                <Modal show={showAddApplicationPopup} handleClose={hideAddApplicationPopup} >
                    <div className="modal-headerhideAddApplicationPopup">
                        <h2>Add Claim Application</h2>
                    </div>
                    <div className="modal-body" >
                        <div className='row'>
                            <DynamicFormFields
                                fields={formFields}
                                callFrom={'add_claim'}
                                fetchInsuranceForClaimById={fetchInsuranceForClaimById}
                                fetchMMV={fetchMMVList}
                                claimType= {claimType}
                            />
                        </div>
                        {
                            claimState?.insuranceData && Object.keys(claimState?.insuranceData || {}).length
                                ? <div className="info-bar">
                                    <ul>
                                        {
                                            times(5, (idx: any) => {
                                                let keys = ['lead_id', 'payment_status', 'remaining_instalment', 'remaining_amount', 'status_name']
                                                let keyName = ['Insurance ID', 'Payment', 'Remaining Instalment', 'Remaining Amount', 'Status']
                                                return (
                                                    <React.Fragment key={`${keys[idx]}_${idx}`}>
                                                        {

                                                            (idx === 4 ? true : claimState?.insuranceData?.[keys[idx]]) !== null
                                                                ? <li className={`info-bar-span ${((claimState?.insuranceData?.["payment_status"] === "Not Paid" || (claimState?.insuranceData?.["payment_status"] === "Fully Paid" && claimState?.insuranceData?.["remaining_instalment"] === null)) ? "full-li" : "") }`} key={idx + "_" + keyName[idx]}>
                                                                    {
                                                                        <>
                                                                            <label>{keyName[idx] + ":" + " "}</label>

                                                                            <span>
                                                                                {'\u00A0'}
                                                                                {

                                                                                    (idx === 4 ? "Policy Shared" : (keys[idx] === "remaining_amount" ? addCurrencyFormatting(claimState?.insuranceData?.[keys[idx]]) : claimState?.insuranceData?.[keys[idx]]))
                                                                                }
                                                                            </span>
                                                                        </>
                                                                    }
                                                                </li>
                                                                : undefined
                                                        }
                                                    </React.Fragment>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                                : undefined
                        }
                    </div>
                    <div className='modal-footer'>
                        <div className="btn-continue"><button type="button" className="btn-primary" onClick={handleSubmit} disabled={!formFields?.length}>Add Application</button></div>
                    </div>
                </Modal>
            </div>
            {
                showApplicationPopup && <AddApplicationPopup show={showAddApplicationPopup} handleClose={() => {
                    setShowApplicationPopup(false)
                    hideAddApplicationPopup()
                }} fields={popupFields} />
            }
        </>
    )
}