import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MASTER_CONFIG } from "@src/services/templatedata.gql";
import client from '../apolloClient';

const common = createSlice({
    name: 'common',
    initialState: {
        common: {},
        mmvList: {},
        regionList: {},
        formFields: [],
        documentNotifications: {}
    },
    reducers: {
        setMasterConfig: (state, action: PayloadAction<any>) => {
            state.common = action.payload
        },

        mmvList: (state: any, action) => {
            const { category, data } = action.payload;
            state.mmvList[category] = data;            
        },

        regionList: (state, action) => {
            state.regionList = action.payload;
        },

        formFields: (state, action) => {
            state.formFields = action.payload;
        },
        
        documentNotifications: (state, action) => {
            state.documentNotifications = action.payload;
        }
    }
});


export default common.reducer

const { setMasterConfig , mmvList, regionList, formFields, documentNotifications } = common.actions

// export const setMasterConfigData = (data: any) => async (dispatch: any) => {
    // dispatch(setMasterConfig(data))

// }

export const getMasterConfig = () => (dispatch: any) => {
	return new Promise((resolve, reject) => {
			// executeGraphQLQuery(MASTER_CONFIG(), client).then((response: any) => {
        client.query({ query: MASTER_CONFIG() })
        .then(
            (response:any)=>{
               if (response && response.data && response.data.masterConfig) {
					let result = response.data.masterConfig.data;
					dispatch(setMasterConfig(result))
					resolve(result)
				} else {
					dispatch(setMasterConfig({}))
					resolve({})
				}
			},
            (error:any) => {
                reject(error);
            
            });
	});
}

export const setMMVList = (category: any, data: any) => async (dispatch: any) => {
    dispatch(mmvList({category, data}))
}

export const setRegionList = (data: any) => async (dispatch: any) => {
    dispatch(regionList(data));
}

export const setFormFields = (data: any) => async (dispatch: any) => {
    dispatch(formFields(data));
}

export const setDocumentNotifications = (data: any) => async (dispatch: any) => {
    dispatch(documentNotifications(data))
}
