import { useEffect, useState } from 'react';
import { executeGraphQLQuery } from "../../common/executeGraphQLQuery";
import { UPDATE_CLAIM_ASSIGN_USER } from "../../services/claimdata.gql";
import { useApolloClient } from '@apollo/client';
import Pagination from '../../common/Pagination';
import Select from 'react-select';
import { getUserListing } from '../../store/slices/UserSlice';
import { useDispatch } from 'react-redux';
import { toast } from "react-toastify";
import LeadHistoryModal from '../../common/LeadHistoryModal';
import { useNavigate } from 'react-router-dom';
import { ROLE_FEATURES } from '@src/config/constant';
import * as HELPER from '@src/config/helper';

const ClaimTable = (props: any) => {
    let { paginationData, claimList, showSelectedAssignSave, setShowSelectedAssignSave, selectedFilters } = props;
    let [loading, setLoading] = useState(false);
    let [userList, setUserList] = useState([]);
    const [viewTimeLine, setViewTimeLine] = useState(false);
    const [selectedRemarkLead, setSelectedRemarkLead] = useState<any>(0);
    const [initialAssignUserId, setInitialAssignUserId] = useState<any>(null);

    const client = useApolloClient();

    const dispatch: any = useDispatch();

    const navigate = useNavigate();

    // Function to handle pagination data requests
    const handleRequestData = (params: any) => {
        setLoading(true);
        let apiParams = {
            page_number: params.page_no || 1,
            searchKeyword: selectedFilters?.searchKeyword
        }
        props.getClaimList(apiParams);
    }

    const getUserListDetails = async () => {

        setLoading(true);

        dispatch(getUserListing({ filter_list: {} })).then((res: any) => {
            if (res && res.status === 200) {
                setUserList(res.data);

            }
            setLoading(false);
        }).catch((err: any) => {
            setLoading(false);
        })
    };


    useEffect(() => {
        getUserListDetails();
    }, [])

    // fetch only ops user
    const transformedUserList = userList
        .filter((user: any) => user?.assigned_role_ids?.split(',').includes("2"))
        .map((user: any) => ({
            value: user.id,
            label: user.name
        }));

    const handleChangeAssign = (ClaimId: any, e: any) => {
        setInitialAssignUserId(getSelectedAssignUser(claimList.find((item: any) => item.claim_id === ClaimId)?.assign_user_id));
        let updatedInsuranceListData = claimList.map((u: any) => { return (u.claim_id === ClaimId) ? { ...u, 'assign_user_id': e.value } : u })
        props.setClaimList(updatedInsuranceListData)
        setShowSelectedAssignSave(ClaimId);
        // setInsuranceListData(updatedInsuranceListData);

    }

    const getSelectedAssignUser = (assignUserId: any) => {
        let getSelectedUser = transformedUserList.find((u) => +u.value === +assignUserId);
        return getSelectedUser || null;
    }

    const saveAssignLeadHandler = async (params: any) => {
        if (params.action === "cancel") {
            let updatedInsuranceListData = claimList.map((u: any) => {
                return (u.claim_id === params.claim_id) ? { ...u, 'assign_user_id': initialAssignUserId?.value } : u
            });
            props.setClaimList(updatedInsuranceListData);

            setShowSelectedAssignSave(0);
            // setInsuranceListData([...insuranceList])
            // let updatedInsuranceListData: any = insuranceList.map((u: any)=> { return (u.hashId === params.hashId) ? {...u, 'assign_user_id': e.value} : u })
            // setInsuranceListData(updatedInsuranceListData);

        } else {
            props.setLoading(true)

            let getLead = claimList.find((ins: any) => ins.claim_id === params.claim_id) || {};
            let assign_user_id = getLead['assign_user_id'];

            let updatedValue = {
                claim_input: {
                    id: params.claim_id,
                    assign_user_id
                }
            };
            const result = await executeGraphQLQuery(UPDATE_CLAIM_ASSIGN_USER(), client, updatedValue)
            if (result?.data?.updateClaimAssignUser?.status === 200) {
                // dynamic erro, because listing api call during update
                toast.success(result?.data?.updateClaimAssignUser?.message);
            }
            props.setLoading(false)

            // let getLead = insuranceListData.find((ins) => ins.hashId === params.hashId) || {};
            // updateLeadAssignUser({ lead_id: params.hashId, "assign_user_id": getLead['assign_user_id'] }).then(res => {
            //     handleRequestData({ ...selectedFilters, page_no: selectedFilters?.activeTab === 'myCases' ? 1 : selectedFilters?.page_number })
            //     setShowSelectedAssignSave(0)
            // });
            setShowSelectedAssignSave(0)
        }
    }

    const handleViewTimeLine = (type: boolean, ClaimId: any) => {
        setViewTimeLine(type);
        setSelectedRemarkLead(ClaimId);
    }

    const detailPageRedirect = (leadId: string) => {
        navigate(`/claim-details/${leadId}?type=claim-details`);
    }

    return (
        <>
            <div className='data-table'>
                <table>
                    <thead>
                        <tr>
                            <th>Claim ID</th>
                            <th>Vehicle Details</th>
                            <th>Insurance Provider</th>
                            <th>Insurance Type</th>      
                            <th>Status</th>
                            <th>Policy Number</th>
                            <th>Customer Name</th>
                            <th>Assigned To</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (claimList.length > 0)
                                ?
                                claimList.map((val: any) => (
                                    <tr key={val.claim_id}>

                                        <td style={{ cursor: "pointer" }} onClick={() => detailPageRedirect(val.hash_id)}>{val?.claim_id}  </td>

                                        <td><span title={`${val?.make_name}  ${val?.model_name}  ${val?.version_name}`} className='truncate'>{val?.make_name}  {val?.model_name} {val?.version_name}</span>
                                            <span className='d-block'>{val?.plate_number}</span> </td>

                                        <td title={val?.partner_name}>{val?.partner_name}</td>

                                        <td>{val?.insurance_type_name}</td>                    

                                        <td title={val?.status_name}>{val?.status_name}</td>

                                        <td><span title={val?.policy_number} className='truncate'>{val?.policy_number}</span></td>
                                        <td title={val?.customer_name}> {val?.customer_name} </td>

                                        <td>
                                            <div className="select-table">
                                                <fieldset className="single-select">
                                                    <div className="material">
                                                        <Select
                                                            value={getSelectedAssignUser(val.assign_user_id)}
                                                            onChange={(e) => handleChangeAssign(val.claim_id, e)}
                                                            options={transformedUserList}
                                                            placeholder="Assign To"
                                                            className='table-select-box'
                                                            classNamePrefix='table-select-box'
                                                        />

                                                        {
                                                            (showSelectedAssignSave === val.claim_id) &&
                                                            <div className="amt-edit-opt">
                                                                <button className="btn-primary" onClick={() => saveAssignLeadHandler({ claim_id: val.claim_id, action: 'save' })}>Save</button>
                                                                <i className="ic-clearclose" onClick={() => saveAssignLeadHandler({ claim_id: val.claim_id, action: 'cancel' })}></i>
                                                            </div>}
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </td>

                                        <td>

                                            <ul className="action-btn">
                                                <li onClick={() => handleViewTimeLine(true, val.hash_id)} title="History">
                                                    <i className="ic-history"></i>
                                                </li>
                                                {
                                                    HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['create_claim_application'], accessType: 'isVisible' }) &&

                                                    <li className="" onClick={() => detailPageRedirect(val.hash_id)}>
                                                        <i className="ic-remove_red_eyevisibility"></i>
                                                    </li>
                                                }
                                            </ul>

                                        </td>

                                    </tr>
                                ))

                                : <tr><td colSpan={8} style={{ textAlign: 'center' }}>No data found</td></tr>
                        }

                    </tbody>
                </table>

                <div className="pagination-outer">
                    {
                        (paginationData && paginationData['total'] > 10) &&

                        <Pagination
                            paginateData={{
                                page: 1,
                                loading: false,
                                totalLengthWithoutPagination: paginationData.total,
                                paginationData,
                                filteredData: {}
                            }}
                            sendRequestDataToParent={handleRequestData}
                        />
                    }
                </div>
            </div>
            {viewTimeLine && <LeadHistoryModal viewTimeLine={viewTimeLine} selectedRemarkLead={selectedRemarkLead} handleViewTimeLine={(action: boolean, leadId: any) => handleViewTimeLine(action, leadId)} />}
        </>
    )
}

export default ClaimTable;