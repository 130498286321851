import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import { DateRange } from '../../component/DatePicker';
import { getMasterConfig } from "@store/slices/common";
import { useSelector, useDispatch } from "react-redux";
import { executeGraphQLQuery } from "../../common/executeGraphQLQuery";
import { GET_CLAIM_LIST_DATA } from "../../services/claimdata.gql";
import { useApolloClient } from '@apollo/client';
import FullPageLoader from '../../common/FullPageLoader';
import MultiSelect from '../../common/MultiSelect';

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }: any) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, child =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
};

const Category = [
    { value: 'ID Card Customer', label: 'ID Card Customer' },
    { value: 'Selfie', label: 'Selfie' },
    { value: 'ID Card of Spouse/ Guarantor', label: 'ID Card of Spouse/ Guarantor' }
];

const DateType = [
    { value: 'Application Filled', label: 'Application Filled' },
    { value: 'FAP Complete', label: 'FAP Complete' },
    { value: 'Recommend', label: 'Recommend' },
];

const ClaimListFilter = (props: any) => {
    const dispatch: any = useDispatch()
    const commonState = useSelector((state: any) => state.common)
    const insuranceTypes = commonState?.common?.insurance_type || [];
    let [loading, setLoading] = useState(false);
    const [dateDisable, setDateDisable] = useState(true);
    const client: any = useApolloClient();

    useEffect(() => {
        if (!Object.keys(commonState?.common || {})?.length) {
            dispatch(getMasterConfig());
        }
    }, []);


    const handleChange = (selectedOption: any, key: any) => {
        let updatedFilters = props.selectedFilters;
        if (key === "insurance_type") {
            updatedFilters = { ...updatedFilters, [key]: +selectedOption?.target?.value };
        }
        else if (key === "dateRange") {

            // Adjust the start and end date to fix the one day earlier issue
            const startDate = new Date(selectedOption[0]);
            const endDate = new Date(selectedOption[1]);

            // Adding 1 day to the actual selected range to correct the payload
            startDate.setDate(startDate.getDate() + 1);
            endDate.setDate(endDate.getDate() + 1);
            updatedFilters = { ...updatedFilters, startDate: startDate.toISOString(), endDate: endDate.toISOString() };
        }
        else if (key === 'claim_status' || key === 'partner_id') {
            let selectedOptionsArray = selectedOption.map((v: any) => v.value);

            // setSelectedFilters({ ...selectedFilters, [key]: selectedOptionsArray });
            updatedFilters = { ...updatedFilters, [key]: selectedOptionsArray };

        }
        else if (key === 'vehicle_type_id') {
            let selectedOptionsArray = selectedOption.map((v: any) => v.vals).flat();
            // let selectedOptionsArray = selectedOption.map((v:any) => v.vals);
            // setSelectedFilters({ ...selectedFilters, [key]: selectedOptionsArray });
            updatedFilters = { ...updatedFilters, [key]: selectedOptionsArray };
        }

        else {
            if (key === 'date_type') {
                setDateDisable(false);
            }
            //   let selectedOptionsArray = selectedOption.map((v) => v.value);
            updatedFilters = { ...updatedFilters, [key]: selectedOption.value };
        }
        props.setSelectedFilters(updatedFilters);
    };



    const onFilterSearch = async (type?: any) => {
        setLoading(true);
        let updatedFilters = {}

        if (type && type === 'reset') {
            updatedFilters = {
                page_number: 1,
                searchKeyword: ""
            }
            setDateDisable(true)
            props.setBtnEnable(false)
        } else {
            updatedFilters = {
                ...props.selectedFilters,
                page_number: 1
            }
        }

        props.setSelectedFilters(updatedFilters);
        setLoading(true);
        await props.getClaimList(updatedFilters, type);

    }

    return (
        <React.Fragment>
            {/* <FullPageLoader show={loading} /> */}
            <div className="lead-list-filter m-lg-t">
                <div className="filter-filed-form">
                    <fieldset className="single-select">
                        <div className="material">
                            <MultiSelect
                                key={"dropdown"}
                                options={commonState?.common?.claim_status || []}
                                value={commonState?.common?.claim_status?.filter((v: any) => props.selectedFilters.claim_status?.includes(v.value))}
                                onChange={(e: any) => handleChange(e, 'claim_status')}
                                isSelectAll={true}
                                placeholder={"Status"}
                                className="react-select"
                                classNamePrefix="react-select"
                            // menuIsOpen = {true}
                            />
                        </div>
                    </fieldset>
                    <fieldset className="single-select">
                        <div className="material">
                            <MultiSelect
                                key={"dropdown"}
                                options={commonState?.common?.partner_list || []}
                                value={commonState?.common?.partner_list?.filter((v: any) => props.selectedFilters.partner_id?.includes(v.value))}
                                onChange={(e: any) => handleChange(e, 'partner_id')}
                                isSelectAll={true}
                                placeholder={"Insurance Provider"}
                                className="react-select"
                                classNamePrefix="react-select"
                            />
                        </div>
                    </fieldset>

                    <fieldset className="single-select">
                        <div className="material">
                            <MultiSelect
                                key={"dropdown"}
                                options={commonState?.common?.groupedVehicleTypes || []}
                                // value={commonState?.common?.vehicle_type?.filter((v:any) => props.selectedFilters.vehicle_type_id?.includes(v.vals))}
                                value={commonState?.common?.groupedVehicleTypes?.filter((v: any) =>
                                    v?.vals?.some((val: any) => props?.selectedFilters?.vehicle_type_id?.includes(val))
                                )}
                                onChange={(e: any) => handleChange(e, 'vehicle_type_id')}
                                isSelectAll={true}
                                placeholder={"Vehicle Type"}
                                className="react-select"
                                classNamePrefix="react-select"
                            />
                        </div>
                    </fieldset>

                    <fieldset className="single-select">
                        <div className="material">
                            <Select components={{ ValueContainer: CustomValueContainer }}
                                options={commonState?.common?.date_type}
                                placeholder="Date Type"
                                className="react-select"
                                classNamePrefix="react-select"
                                onChange={(e) => handleChange(e, 'date_type')}
                                value={commonState?.common?.date_type?.filter((e: any) => e.value === props?.selectedFilters?.['date_type'])}
                            />
                        </div>
                    </fieldset>

                    <fieldset className="single-select">
                        <div className="material">
                            <DateRange
                                onDateChange={handleChange}
                                disabled={dateDisable}
                                reset={props?.selectedFilters?.['startDate'] ? false : true}
                            />
                        </div>
                    </fieldset>

                    <fieldset className="from-group radio-type-txt">
                        <label className="form-label">Insurance Type</label>
                        <div className='radio-btn d-flex'>
                            {insuranceTypes.map((insurance: any) => (
                                <div key={insurance.value} className="custom-control custom-checkbox">
                                    <input
                                        id={insurance.label}
                                        type="radio"
                                        className="custom-control-input"
                                        name="InsuranceType"
                                        value={insurance.value}
                                        onChange={(e) => handleChange(e, 'insurance_type')}
                                        checked={insurance.value === props.selectedFilters['insurance_type']}
                                    />
                                    <label className="custom-control-label" htmlFor={insurance.label}>
                                        {insurance.label}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </fieldset>

                    <div className='btn-search-reset'>
                        <button className='btn-primary' onClick={onFilterSearch}> Search </button>
                        {/* <button className='btn-primary' onClick={() => onFilterSearch('reset')}> Reset </button> */}
                        <p className="m-xl-l p-reset" onClick={() => onFilterSearch('reset')}>Reset</p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ClaimListFilter;
