import { getFormConfig, getMMVList, getRegionListing } from '@src/store/action/allAction';
import { getMasterConfig } from "@store/slices/common";
import DynamicFormFields from '../create/DynamicFormFields';
import { createClaimFields, updateFormFields, validateInputFields } from '@src/common/FunctionList';
import { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClaimProcessingData, updateClaim, updateClaimDetailsInStore } from '@src/store/slices/claim';
import { toast } from 'react-toastify';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ROLE_FEATURES, STATUS_SUB_STATUS, VEHICLE_TYPE_COMMERCIAL } from '@src/config/constant';
import * as HELPER from '@src/config/helper';
import { cloneDeep } from 'lodash';

export default function ClaimDetailsForm(props: any) {
    const location = useLocation()
    const [formFields, setFormFields] = useState<any>([])
    const [masterData, setMasterData] = useState<any>({})
    const [refresh, setRefresh] = useState<boolean>(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const selectedRouteType = searchParams.get('type');

    const client = useApolloClient();
    const dispatch: any = useDispatch()

    const claimState: any = useSelector((state: any) => state.claim)
    const commonState: any = useSelector((state: any) => state.common)

    const claimId = location?.pathname?.split('/')?.[2] || null
    const navigate = useNavigate();
    

    const fetchFormConfig = async () => {
        let result: any = null
        if (!formFields.length) {
            if (!commonState?.formFields?.length) {
                result = await dispatch(getFormConfig(client))
                result = result?.data?.claimFormFields;
            } else {
                result = cloneDeep(commonState?.formFields);
            }
            result = result?.filter((e: any) => e.name !== 'insurance_id');
        } else {
            result = formFields
        }

        let updatedFields = updateFormFields(result, {}, masterData)
        setFormFields(updatedFields)
    }

    const fetchMMVList: any = async (params: any) => {
        if (commonState?.mmvList && Object.keys(commonState.mmvList || {})?.length) {
            return commonState?.mmvList?.make
        } else {
            const mmvList: any = await dispatch(getMMVList(client, { params: { ...params } }))
            if (mmvList && mmvList?.status === 200) {
                return mmvList?.data?.make
            }
        }
        return {}
    }

    const fetchMasterData = async () => {
        let data: any = {}
        if (commonState?.common && Object.keys(commonState.common || {})?.length) {
            data = commonState.common
        } else {
            data = await dispatch(getMasterConfig())
        }

        return data
    }

    const getRegionList = async () => {
        let result: any = null
        if (Object.keys(commonState?.regionList).length) {
            result = commonState?.regionList;
        } else {
            result = await dispatch(getRegionListing(client))
        }
        if (result?.data?.length) {
            return result.data
        }
        return []
    }

    const getClaimProcessingData = async () => {
        try {
            const claimData: any = await fetchClaimProcessingData({ type: 'applicationDetails', variables: { claim_id: claimId, api_type: 'applicationDetails' } })
            await dispatch(updateClaimDetailsInStore({ ...claimState?.claimDetails, ...claimData?.data }))
        } catch (error) {
            navigate('/claim-list');
        }
    
    }

    const callApis = async () => {
        props.setLoader(true)
        const masterData = await fetchMasterData()

        const mmvParams: any = {}

        if (VEHICLE_TYPE_COMMERCIAL.includes(claimState?.claimDetails?.vehicle_type_id)) {
            mmvParams['category'] = masterData?.vehicle_type?.find((e: any) => e.value === claimState?.claimDetails?.vehicle_type_id)?.category_id
        }

        const mmvData = await fetchMMVList(mmvParams)
        const regionData = await getRegionList()

        setMasterData({ ...masterData, make: { ...mmvData }, region_list: [...regionData] })
        props.setLoader(false)
    }

    const validateFields = () => {
        let error = false
        error = validateInputFields(formFields)

        setRefresh(!refresh)
        return error
    }

    const handleSave = async () => {
        props.setLoader(true)
        let checkError = validateFields()

        if (checkError) {
            props.setLoader(false)
            return
        }

        let { claimDetails, vehicleDetails, customerDetails } = createClaimFields(formFields, 'update', claimState?.claimDetails)

        //appending claim id
        claimDetails['id'] = claimState?.claimDetails?.id
        try {
            const result: any = await updateClaim(client, { claim_details: claimDetails, vehicle_details: vehicleDetails, customer_details: customerDetails, type: 'update' })
            if (result?.data?.create_claim?.data?.id) {
                await getClaimProcessingData()
                setSearchParams(`?${new URLSearchParams({ type: 'document-upload' })}`)
                toast.success("Data Saved Successfully")
            } else {
                toast.error('Error in Updating Application')
            }

        } finally {
            props.setLoader(false)
        }

    }

    useEffect(() => {
        callApis()
    // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (masterData && Object.keys(masterData)?.length) {
            fetchFormConfig()
        }
    }, [masterData])

    useEffect(() => {
        if (!claimState?.claimDetails?.id) {
            getClaimProcessingData()
        }
    }, [claimState?.claimDetails])

    return (
        <>
            <div className="form-tab-right-panel">
                <div className="lead-detail-from-outer">
                    <div className="lead-form-heading">
                        <h2>Claim Application Details</h2>
                    </div>
                    <div className="lead-form-filed p-lg-t">
                        <div className='row'>
                            {
                                formFields?.length
                                    ?
                                    <DynamicFormFields
                                        fields={formFields}
                                        callFrom={"edit_claim"}
                                        fetchMMV={() => { }}
                                    />
                                    : undefined
                            }
                        </div>
                        <div className="btn-continue">
                            {
                                (!HELPER.isUserHasAccessPage({ permissionID: ROLE_FEATURES['update_claim_application'], accessType: 'edit', assignUserId: claimState?.claimDetails?.assign_user_id }) ||
                                    claimState?.claimDetails?.status_id > STATUS_SUB_STATUS['status']['application'])
                                    ?
                                    <button type="button" className="btn-primary" disabled>Continue</button>
                                    :
                                    <button type="button" className="btn-primary" onClick={handleSave}>Continue</button>
                            }
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}