import { useEffect, useState } from 'react';
import ClaimDetailsTab from './ClaimDetailsTabs'
import { getRibbonData, updateClaimDetailsInStore, updateClaimHistory, updateClaimRibbonData } from '@src/store/slices/claim';
import { useLocation, useNavigate } from 'react-router-dom';
import FullPageLoader from '@src/view/common/FullPageLoader';
import useClaimProcessingData from '@src/hooks/useClaimProcessingData';
import { useDispatch, useSelector } from 'react-redux';
import { useApolloClient } from '@apollo/client';

export default function ClaimDetails(props: any) {
    const location = useLocation();
    const [loading, setLoading] = useState<boolean>(false)
    const [showClaimDetailsTab, setShowClaimDetailsTab] = useState(false); 

    const claimId = location?.pathname?.split('/')?.[2] || null
    const dispatch: any = useDispatch()
    const claimState = useSelector((state: any) => state.claim)
    const client = useApolloClient();
    const navigate = useNavigate();

    
    useClaimProcessingData({ type: 'applicationDetails', api_type: 'applicationDetails', call_from: 'applicationDetails' })

    const getClaimRibbonData = async () => {
        try {
            setLoading(true);
            if (claimId) {
                await dispatch(getRibbonData(client, { claim_id: claimId }))
            }
        } catch (error) {
            navigate('/claim-list')
        }
    }

    useEffect(() => {
        getClaimRibbonData()

        return (() => {
            dispatch(updateClaimDetailsInStore({}))
            dispatch(updateClaimRibbonData([]))
            dispatch(updateClaimHistory([]))
        })
    // eslint-disable-next-line
    }, [])



    return (
        <>
            <FullPageLoader show={loading} />
            <div className="container-fluid">
               <ClaimDetailsTab setLoader={setLoading} />
            </div>

        </>
    )
}